import { zonedTimeToUtc } from 'date-fns-tz';

import { FiorentinaTeamId, WomenFiorentinaTeamId } from '@app/services/opta/constants/teamConstants';
import { convertTeamId } from '@app/services/opta/converters/teamsConverter';
import { getMenTeamLogoUrl, getWomenTeamLogoUrl } from '@app/helpers/sourceHelpers';
import {
  MatchPeriodsStatusMap, MatchStatusMap, MatchHalfPeriodsMap, MatchHalfTimePeriodsMap,
} from '@app/constants/matchConstants';

import { AppState } from '@app/types/appStateTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { TeamNames } from '@app/types/teamsTypes';
import {
  MatchResult, LiveMatchResult, MatchStatus, LiveMatchResults, Game, MatchPeriod, MatchStatistics, MatchLineupsTeamStat,
  MatchLineups, MatchLineupsTeam, MatchLineupsTeamPlayer, MatchTeam,
} from '@app/types/matchTypes';
import {
  OptaScheduleLiveResponse,
  OptaScheduleLiveResult,
  OptaScheduleLiveResultItem,
  OptaScheduleStaticResponse,
  OptaSDScheduleStaticResponse,
  OptaScheduleStaticTeamData,
  OptaStandingsTeam,
  OptaLineupsResponse,
  OptaLineupsTeamMatchPlayer,
  OptaLineupsTeamData,
  OptaLineupsTeamsPlayer,
  OptaLineupsTeamGoal,
  OptaLineupsTeamMatchPlayerStat,
  OptaLineupsTeamsPlayerName,
  OptaLineupsStats,
} from '@app/services/opta/types/responseTypes';

const formatMatchPeriod = (period = ''): MatchPeriod => (
  period
    .toLowerCase()
    .replace(/ /g, '')
    .replace(/-/g, '') as MatchPeriod
);

const getMatchGameId = (uID: string, liveResults: LiveMatchResults): string => (
  Object.values(liveResults ?? {}).find(({ gameId }) => uID === gameId)?.gameId ?? ''
);

const getMatchStatus = (period: string): MatchStatus => (
  MatchPeriodsStatusMap[formatMatchPeriod(period)] ?? MatchStatusMap.finished
);

export const getMatchTeamName = (team?: MatchTeam, isShortName?: boolean): string => (team?.name.length && !isShortName ? team?.name : team?.shortName ?? '');

const getSDMatchStatus = (period: string): MatchStatus => (
  formatMatchPeriod(period) === 'played' ? MatchStatusMap.finished : MatchStatusMap.upcoming
);

export const getGameByGameOptaId = (state: AppState, language: LanguageType, gameOptaId: string): Game | undefined => (
  state?.matchCenter?.games?.[language]?.find((game) => gameOptaId.replace('g', '') === game.gameOptaId.replace('g', ''))
);

export const getGameByUrlSlug = (state: AppState, language: LanguageType, thirdLevel: string): Game | undefined => (
  state.matchCenter.games?.[language]?.find((game) => thirdLevel === game.urlSlug)
);

export const getMatchTime = (period: string, timeStamp: string): string => {
  const currentTime = new Date().getTime();
  const matchStartTime = zonedTimeToUtc(timeStamp, 'Europe/London').getTime();
  const millisecondsIn = {
    day: 86400000,
    hour: 3600000,
    minute: 60000,
  };
  const minutes = Math.ceil(
    (((currentTime - matchStartTime) % millisecondsIn.day) % millisecondsIn.hour) / millisecondsIn.minute,
  ) ?? 1;

  switch (formatMatchPeriod(period)) {
    case MatchHalfPeriodsMap.firsthalf:
      return minutes > 45 ? `45 + ${minutes - 45}'` : `${minutes}'`;
    case MatchHalfPeriodsMap.secondhalf:
      return minutes > 45 ? `90 + ${minutes - 45}'` : `${minutes + 45}'`;
    case MatchHalfPeriodsMap.firstextrahalf:
    case MatchHalfPeriodsMap.extrafirsthalf:
      return minutes + 90 > 105 ? `105 + ${minutes - 15}'` : `${minutes + 90}'`;
    case MatchHalfPeriodsMap.secondextrahalf:
    case MatchHalfPeriodsMap.extrasecondhalf:
      return minutes + 105 > 120 ? `120 + ${minutes - 15}'` : `${minutes + 105}'`;
    default: return '';
  }
};

export const getLineupFormation = (formation = ''): number[] => {
  switch (formation) {
    case '442': return [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9];
    case '41212': return [1, 2, 5, 6, 3, 4, 7, 11, 8, 10, 9];
    case '433': return [1, 2, 5, 6, 3, 7, 4, 8, 10, 9, 11];
    case '451': return [1, 2, 5, 6, 3, 7, 4, 10, 8, 11, 9];
    case '4411': return [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9];
    case '4141': return [1, 2, 5, 6, 3, 4, 7, 8, 10, 11, 9];
    case '4231': return [1, 2, 5, 6, 3, 8, 4, 7, 10, 11, 9];
    case '4321': return [1, 2, 5, 6, 3, 8, 4, 7, 10, 11, 9];
    case '532': return [1, 2, 6, 5, 4, 3, 7, 8, 11, 10, 9];
    case '541': return [1, 2, 6, 5, 4, 3, 7, 8, 10, 11, 9];
    case '352': return [1, 6, 5, 4, 2, 7, 11, 8, 3, 10, 9];
    case '343': return [1, 6, 5, 4, 2, 7, 8, 3, 10, 9, 11];
    case '31312': return [1, 6, 5, 4, 7, 2, 8, 3, 9, 10, 11];
    case '4222': return [1, 2, 5, 6, 3, 4, 8, 7, 11, 10, 9];
    case '3511': return [1, 6, 5, 4, 2, 7, 11, 8, 3, 10, 9];
    case '3421': return [1, 6, 5, 4, 2, 7, 8, 3, 10, 11, 9];
    case '3412': return [1, 6, 5, 4, 2, 7, 8, 3, 9, 10, 11];
    case '3142': return [1, 5, 4, 6, 8, 2, 7, 11, 3, 9, 10];
    case '4132': return [1, 2, 5, 6, 3, 4, 7, 8, 11, 9, 10];
    case '4240': return [1, 2, 5, 6, 3, 4, 8, 7, 9, 10, 11];
    case '4312': return [1, 2, 5, 6, 3, 7, 4, 11, 8, 9, 10];
    case '343d': case '31213': return [1, 6, 5, 4, 8, 2, 3, 7, 10, 9, 11];
    case '3331': return [1, 6, 5, 4, 2, 8, 3, 10, 7, 11, 9];
    case '3241': return [1, 6, 5, 4, 2, 3, 10, 7, 8, 11, 9];
    default: return [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9];
  }
};

export const getPlayerNumberByFormation = (
  players: MatchLineupsTeamPlayer[] | undefined, formation: string | number,
): number => Number(players?.find(({ place }) => Number(place) === Number(formation))?.['number']) ?? 0;

interface MapMatchResult {
  result: OptaScheduleStaticTeamData;
  liveResults: LiveMatchResults;
  teams: OptaStandingsTeam[];
  teamNames: TeamNames;
  seasonId: string;
  optaId: string;
}
export const mapMatchResult = ({
  result, liveResults, teams, teamNames, seasonId, optaId,
}: MapMatchResult): MatchResult => {
  const firstTeamId = convertTeamId(result?.TeamData[0]?.['@attributes']?.TeamRef ?? '');
  const secondTeamId = convertTeamId(result?.TeamData[1]?.['@attributes']?.TeamRef ?? '');
  const gameId = getMatchGameId(result?.['@attributes']?.uID?.replace('g', '') ?? '', liveResults);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const matchDate = result?.MatchInfo?.Date?.['@value'] ?? result?.MatchInfo?.Date;

  return {
    gameId,
    seasonId,
    competitionOptaId: optaId,
    gameOptaId: result?.['@attributes']?.uID,
    matchDay: result?.MatchInfo['@attributes']?.MatchDay,
    matchDateTimeConfirmed: true,
    matchDate: `${zonedTimeToUtc(matchDate, 'Europe/London')}`,
    matchStatus: liveResults?.[gameId]?.matchStatus ?? getMatchStatus(result?.MatchInfo['@attributes']?.Period.toLowerCase()),
    matchTime: liveResults?.[gameId]?.matchTime ?? '',
    roundType: result?.MatchInfo['@attributes']?.RoundType?.toLowerCase(),
    isHalfTime: liveResults?.[gameId]?.isHalfTime ?? false,
    ticketUrl: '',
    teams: {
      first: {
        id: firstTeamId,
        name: teamNames?.[firstTeamId]?.teamName ?? teams
          .find((team) => team['@attributes']?.uID === result?.TeamData[0]?.['@attributes']?.TeamRef)?.Name,
        shortName: teamNames?.[firstTeamId]?.shortTeamName || '',
        goals: liveResults?.[gameId]?.teams?.first?.goals ?? result?.TeamData[0]?.['@attributes']?.Score,
        logoUrl: getMenTeamLogoUrl(firstTeamId),
      },
      second: {
        id: secondTeamId,
        name: teamNames?.[secondTeamId]?.teamName ?? teams
          .find((team) => team['@attributes']?.uID === result?.TeamData[1]?.['@attributes']?.TeamRef)?.Name,
        shortName: teamNames?.[secondTeamId]?.shortTeamName || '',
        goals: liveResults?.[gameId]?.teams?.second?.goals ?? result?.TeamData[1]?.['@attributes']?.Score,
        logoUrl: getMenTeamLogoUrl(secondTeamId),
      },
    },
  };
};

export const mapLiveMatchResult = (result: OptaScheduleLiveResult, seasonId = '', optaId = ''): LiveMatchResult => ({
  seasonId,
  competitionOptaId: optaId,
  gameId: result['@attributes']?.['game-id'] ?? '',
  matchTime: getMatchTime(result['@attributes']?.period, result['@attributes']?.timestamp ?? ''),
  matchStatus: getMatchStatus(result['@attributes']?.period ?? ''),
  isHalfTime: formatMatchPeriod(result['@attributes']?.period ?? '') === MatchHalfTimePeriodsMap.halftime,
  teams: {
    first: {
      id: result['home-team']?.['team-id'] ?? '',
      name: result['home-team']?.['team-name'] ?? '',
      shortName: result['home-team']?.['team-code'] ?? '',
      goals: result['home-team']?.score ?? '',
      logoUrl: getMenTeamLogoUrl(result['home-team']?.['team-id'] ?? ''),
    },
    second: {
      id: result['away-team']?.['team-id'] ?? '',
      name: result['away-team']?.['team-name'] ?? '',
      shortName: result['away-team']?.['team-code'] ?? '',
      goals: result['away-team']?.score ?? '',
      logoUrl: getMenTeamLogoUrl(result['away-team']?.['team-id'] ?? ''),
    },
  },
});

interface FormatLiveMatchResultsProps {
  results: OptaScheduleLiveResultItem | OptaScheduleLiveResultItem[];
  seasonId: string;
  optaId: string;
}

export const formatLiveMatchResults = ({
  results, seasonId, optaId,
}: FormatLiveMatchResultsProps): LiveMatchResult[] => {
  let accumulator: LiveMatchResult[] = [];

  if (Array.isArray(results)) {
    results.forEach((result) => {
      if (Array.isArray(result?.result)) {
        accumulator = accumulator.concat(formatLiveMatchResults({
          results: result?.result ?? [], seasonId, optaId,
        }));
      } else {
        accumulator.push(mapLiveMatchResult(result?.result ?? result, seasonId, optaId));
      }
    });
  } else if (Array.isArray(results?.result)) {
    accumulator = accumulator.concat(formatLiveMatchResults({
      results: results?.result, seasonId, optaId,
    }));
  } else {
    accumulator.push(mapLiveMatchResult(results?.result ?? results, seasonId, optaId));
  }

  return accumulator
    .filter((result) => [
      result?.teams?.first?.id,
      result?.teams?.second?.id,
    ].includes(FiorentinaTeamId));
};

interface MapLiveMatchResults {
  liveData: OptaScheduleLiveResponse;
  seasonId: string;
  optaId: string;
}
export const mapLiveMatchResults = ({ liveData, seasonId, optaId }: MapLiveMatchResults): LiveMatchResults => {
  const results = liveData?.feed?.['content.item']?.['content.body']?.results ?? {};
  return formatLiveMatchResults({ results, seasonId, optaId })
    .reduce((accumulator, result) => {
      accumulator[result.gameId] = result;
      return accumulator;
    }, {} as LiveMatchResults);
};

interface MapMatchResultsSeasonData {
  seasonId: string;
  optaId: string;
}
export const mapMatchResults = (
  [liveData, staticData, teamNames]: [OptaScheduleLiveResponse, OptaScheduleStaticResponse, TeamNames],
  { seasonId, optaId }: MapMatchResultsSeasonData,
): MatchResult[] => {
  const teams = staticData?.SoccerFeed?.SoccerDocument?.Team || [];
  const results = staticData?.SoccerFeed?.SoccerDocument?.MatchData || [];
  const liveResults = mapLiveMatchResults({ liveData, seasonId, optaId });
  return results
    .filter((result) => [
      result?.TeamData[1]?.['@attributes']?.TeamRef,
      result?.TeamData[0]?.['@attributes']?.TeamRef,
    ].find((teamRef) => [
      `t${FiorentinaTeamId}`,
      `t${WomenFiorentinaTeamId}`,
    ].includes(teamRef)))
    .map((result) => mapMatchResult({
      result, liveResults, teams, teamNames, seasonId, optaId,
    }));
};

export const mapSDMatchResults = (
  [results, teamNames]: [OptaSDScheduleStaticResponse, TeamNames],
  { seasonId, optaId }: MapMatchResultsSeasonData,
): MatchResult[] => (
  (results?.match ?? [])
    .map((result): MatchResult => ({
      gameId: '',
      seasonId,
      gameOptaId: '',
      competitionOptaId: optaId,
      matchDay: result?.matchInfo?.week ?? '',
      matchDate: `${zonedTimeToUtc(
        `${(result?.matchInfo?.date ?? '').replace('Z', '')} ${(result?.matchInfo?.time ?? '').replace('Z', '')}`,
        'UTC/GMT+0',
      )}`,
      matchDateTimeConfirmed: !!result?.matchInfo?.time?.length,
      matchStatus: getSDMatchStatus(result?.liveData?.matchDetails?.matchStatus ?? ''),
      matchTime: '',
      isHalfTime: false,
      ticketUrl: '',
      teams: {
        first: {
          id: result?.matchInfo?.contestant?.[0]?.id ?? '',
          name: teamNames?.[result?.matchInfo?.contestant?.[0]?.id]?.teamName
            ?? result?.matchInfo?.contestant?.[0]?.shortName ?? '',
          shortName: teamNames?.[result?.matchInfo?.contestant?.[0]?.id]?.shortTeamName
            ?? result?.matchInfo?.contestant?.[0]?.shortName ?? '',
          goals: result?.liveData?.matchDetails?.scores?.total[result?.matchInfo?.contestant?.[0]?.position ?? ''] ?? '',
          logoUrl: getWomenTeamLogoUrl(result?.matchInfo?.contestant?.[0]?.id ?? ''),
        },
        second: {
          id: result?.matchInfo?.contestant?.[1]?.id ?? '',
          name: teamNames?.[result?.matchInfo?.contestant?.[1]?.id]?.teamName
            ?? result?.matchInfo?.contestant?.[1]?.shortName ?? '',
          shortName: teamNames?.[result?.matchInfo?.contestant?.[1]?.id]?.shortTeamName
            ?? result?.matchInfo?.contestant?.[1]?.shortName ?? '',
          goals: result?.liveData?.matchDetails?.scores?.total[result?.matchInfo?.contestant?.[1]?.position ?? ''] ?? '',
          logoUrl: getWomenTeamLogoUrl(result?.matchInfo?.contestant?.[1]?.id ?? ''),
        },
      },
    }))
    .sort((a, b) => new Date(a.matchDate).getTime() - new Date(b.matchDate).getTime())
);

const mapMatchTeamPlayerName = (name?: OptaLineupsTeamsPlayerName): string => (
  name?.Known ? name?.Known : `${(name?.First ?? '').substring(0, 1)}. ${name?.Last ?? ''}`);

const mapMatchTeamPlayerPlace = (stats: OptaLineupsTeamMatchPlayerStat[]): number => ('find' in stats ? Number(stats
  .find((playerStat) => playerStat?.['@attributes']?.Type === 'formation_place')?.['@value']) ?? 0 : 0);

const mapMatchTeamGoals = (goals: OptaLineupsTeamGoal[]) => ('map' in goals ? goals.map((goal) => ({
  time: goal?.['@attributes']?.Min && Number(goal?.['@attributes']?.Min) + 1,
  playerId: goal?.['@attributes']?.PlayerRef ?? '',
})) : []);

const mapMatchTeamPlayerData = (player: OptaLineupsTeamMatchPlayer, personName?: OptaLineupsTeamsPlayerName) => ({
  firstName: personName?.First ?? '',
  lastName: personName?.Last ?? '',
  knownName: personName?.Known ?? '',
  name: mapMatchTeamPlayerName(personName),
  playerId: player?.['@attributes']?.PlayerRef ?? '',
  position: player?.['@attributes']?.Position ?? '',
  number: player?.['@attributes']?.ShirtNumber ?? '',
  start: (player?.['@attributes']?.Status ?? '').toLowerCase() === 'Start',
  place: mapMatchTeamPlayerPlace(Array.isArray(player?.Stat) ? player?.Stat : [player?.Stat]),
});

const getMatchLineupStatData = (stats?: OptaLineupsStats | OptaLineupsStats[]): OptaLineupsStats[] => {
  const statsData = stats ?? [];
  return Array.isArray(statsData) ? statsData : [statsData];
};

const getMatchLineupTeamPlayerData = (
  players?: OptaLineupsTeamMatchPlayer | OptaLineupsTeamMatchPlayer[],
): OptaLineupsTeamMatchPlayer[] => {
  const playersData = players ?? [];
  return Array.isArray(playersData) ? playersData : [playersData];
};

export const mapMatchLineupTeamData = (
  team: OptaLineupsTeamData, players: OptaLineupsTeamsPlayer[],
): MatchLineupsTeam => {
  const statsData = getMatchLineupStatData(team?.Stat);
  const playersData = getMatchLineupTeamPlayerData(team?.PlayerLineUp?.MatchPlayer);
  const formation = statsData
    .find((stat) => stat?.['@attributes']?.Type === 'formation_used')?.['@value'] ?? '442';

  return {
    teamId: convertTeamId(team?.['@attributes']?.TeamRef),
    logoUrl: getMenTeamLogoUrl(convertTeamId(team?.['@attributes']?.TeamRef)),
    players: playersData?.map((player) => mapMatchTeamPlayerData(
      player, players.find((p) => p?.['@attributes'].uID === player?.['@attributes']?.PlayerRef)?.PersonName,
    )) ?? [],
    goals: mapMatchTeamGoals(Array.isArray(team?.Goal) ? team?.Goal : [team?.Goal]),
    formation: formation === '343d' ? '31213' : formation,
    stats: Object.values(MatchStatistics).reduce((acc, statType) => {
      acc[statType] = Number(statsData
        .find((stat) => stat?.['@attributes']?.Type?.replace(/ /g, '_').toLowerCase() === statType)
        ?.['@value'] ?? 0) ?? 0;
      return acc;
    }, {} as unknown as MatchLineupsTeamStat),
  };
};

export const mapMatchLineups = (data: OptaLineupsResponse, gameOptaId = ''): MatchLineups => {
  const lineupsData = Array.isArray(data?.SoccerFeed?.SoccerDocument)
    ? data?.SoccerFeed?.SoccerDocument
      .find((data) => (data?.['@attributes'].uID ?? '').replace('f', '') === gameOptaId.replace('g', ''))
    : data?.SoccerFeed?.SoccerDocument;
  const homeTeamData = lineupsData?.MatchData?.TeamData
    ?.find((team) => (team?.['@attributes']?.Side ?? '').toLowerCase() === 'home');
  const homePlayerData = lineupsData?.Team
    ?.find((team) => team?.['@attributes']?.uID === homeTeamData?.['@attributes']?.TeamRef)?.Player ?? [];
  const awayTeamData = lineupsData?.MatchData?.TeamData
    ?.find((team) => (team?.['@attributes']?.Side ?? '').toLowerCase() === 'away');
  const awayPlayerData = lineupsData?.Team
    ?.find((team) => team?.['@attributes']?.uID === awayTeamData?.['@attributes']?.TeamRef)?.Player ?? [];

  return {
    home: mapMatchLineupTeamData(homeTeamData as OptaLineupsTeamData, homePlayerData),
    away: mapMatchLineupTeamData(awayTeamData as OptaLineupsTeamData, awayPlayerData),
  };
};
