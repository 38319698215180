import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Translations from '@app/locales/index';
import AppRoutes from '@app/constants/routesConstants';
import { Devices } from '@app/styles';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { getMatchTeamName } from '@app/helpers/matchHelpers';
import { LocalizedDate } from '@app/components/Localization';
import { MatchCenterButton } from '@app/components/Buttons';
import { useLanguage } from '@app/components/Hooks';
import {
  useLineupsSelector,
  useMatchResultsSelector,
  useTeamScoreSelector,
} from '@app/pages/MatchCenter/MatchCenterHooks';
import { useGameByOptaIdSelector, useGameByUrlSlugSelector } from '../MatchCenterHooks';
import MatchLiveAudioButton from './MatchLiveAudioButton';

const MatchHeader = ({ gameId }: { gameId?: string }): React.ReactElement | null => {
  const gameByUrlSlug = useGameByUrlSlugSelector(); // default game selector for Match Center page
  const gameByOptaId = useGameByOptaIdSelector(gameId); // apply when Match Header is used like a widget on other pages
  const game = gameId ? gameByOptaId : gameByUrlSlug;
  const isCtaVisible = !!gameId;
  const result = useMatchResultsSelector();
  const lineups = useLineupsSelector();
  const homeScore = useTeamScoreSelector(result?.teams.first.id ?? '');
  const awayScore = useTeamScoreSelector(result?.teams.second.id ?? '');
  const language = useLanguage();

  const isMatchLive = result?.matchStatus === MatchStatusMap.live;
  const isMatchFinished = result?.matchStatus === MatchStatusMap.finished;
  const isMatchUpcoming = result?.matchStatus === MatchStatusMap.upcoming;
  const isMatchLiveAudio = isMatchLive && !!game?.audioStreamUrl;
  const showScore = result?.roundType === 'round' || !result?.roundType;

  return (
    <Header>
      <Container>
        {!isCtaVisible && (
          // Show heading whenMatch Status is used on Match Center page - not like a widget on any page
          <Heading>
            {getMatchTeamName(result?.teams.first)}
            &nbsp;
            {getMatchTeamName(result?.teams.second)}
            &nbsp;-&nbsp;
            {game?.competition}
            &nbsp;
            {game?.seasonId}
          </Heading>
        )}
        <Grid>
          <TopArea>
            <Competition>
              {game?.competition}
              &nbsp;-&nbsp;
              <FormattedMessage id="match.day" />
              &nbsp;
              {result?.matchDay}
            </Competition>
            <MatchDate>
              <LocalizedDate date={new Date(result?.matchDate ?? 0)} dateFormat="EEEE dd MMMM" />
            </MatchDate>
          </TopArea>
          <CenterArea>
            <SectionContentCentered>
              <Logo src={result?.teams.first.logoUrl} alt={result?.teams.first.name} />
            </SectionContentCentered>
            <Status>
              {!isMatchUpcoming && (
                <Live isFinished={isMatchFinished}>
                  {isMatchLive ? (<FormattedMessage id="match.live" />) : (<FormattedMessage id="match.final" />)}
                </Live>
              )}
              <Goals>
                {isMatchUpcoming ? 'VS' : (
                  <>
                    {result?.teams.first.goals}
                    &nbsp;
                    <span>-</span>
                    &nbsp;
                    {result?.teams.second.goals}
                  </>
                )}
              </Goals>
              {isMatchLive && (
                <MatchTime>
                  { result?.isHalfTime ? <FormattedMessage id="match.halfTime" /> : result?.matchTime }
                </MatchTime>
              )}
              {isMatchFinished && (<Finished><FormattedMessage id="match.fullTime" /></Finished>)}
              {isCtaVisible && (
                <CtaButton
                  pathKey={AppRoutes.MatchCenter.path}
                  pathParams={{
                    urlSlug: game?.urlSlug ?? '',
                    tab: Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(),
                  }}
                >
                  <FormattedMessage id="match.center" />
                </CtaButton>
              )}
            </Status>
            <SectionContentCentered>
              <Logo src={result?.teams.second.logoUrl} alt={result?.teams.second.name} />
            </SectionContentCentered>
          </CenterArea>
          <BottomArea>
            <SectionContentCentered>
              <TeamName>{getMatchTeamName(result?.teams.first)}</TeamName>
              {showScore ? (<TeamScore>{homeScore}</TeamScore>) : null}
              {lineups?.home?.goals?.map(({ time, playerId }) => time && (
                <TeamGoal key={time}>
                  <span>
                    {time}
                    &rsquo;
                  </span>
                  &nbsp;-&nbsp;
                  {lineups?.home?.players?.find((player) => playerId === player.playerId)?.name
                  ?? lineups?.away?.players?.find((player) => playerId === player.playerId)?.name ?? ''}
                </TeamGoal>
              ))}
            </SectionContentCentered>
            <BottomSectionDivider />
            <SectionContentCentered>
              <TeamName>{getMatchTeamName(result?.teams.second)}</TeamName>
              {showScore ? (<TeamScore>{awayScore}</TeamScore>) : null}
              {lineups?.away?.goals?.map(({ time, playerId }) => time && (
                <TeamGoal key={time}>
                  {lineups?.away?.players?.find((player) => playerId === player.playerId)?.name
                  ?? lineups?.home?.players?.find((player) => playerId === player.playerId)?.name ?? ''}
                  &nbsp;-&nbsp;
                  <span>
                    {time}
                    &rsquo;
                  </span>
                </TeamGoal>
              ))}
            </SectionContentCentered>
          </BottomArea>
          {game && isMatchLiveAudio && (
            <MatchLiveAudioButtonArea>
              <MatchLiveAudioButton game={game} />
            </MatchLiveAudioButtonArea>
          )}
        </Grid>
      </Container>
    </Header>
  );
};

export default MatchHeader;

const Header = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-color: black;

  & > section {
    width: 100%;
  }

  @media ${Devices.tabletLarge} {
    flex-direction: row;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex: 0 0 350px;
  background-color: #5a328c;

  @media ${Devices.tabletLarge} {
    flex: 0 0 100%;
    height: 450px;
  }
`;

const Heading = styled.h1`
  display: none;
`;

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const TopArea = styled.div`
  display: flex;
  flex: 0 0 70px;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 14px;
  line-height: 1.23;

  @media ${Devices.tabletLarge} {
    flex: 1;
  }
`;

const CenterArea = styled.div`
  display: flex;
  flex: 0 0 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BottomArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  width: 100%;

  & > section {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    text-align: center;
  }
`;

const BottomSectionDivider = styled.div`
  flex: 0 0 100px;
`;

const SectionContentCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;

  flex: 1;;
`;

const MatchLiveAudioButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding-top: 15px;
`;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  flex: 0 0 100px;
`;

const MatchTime = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  color: white;
  letter-spacing: normal;
`;

const Finished = styled(MatchTime)`
  color: white;
`;

const Competition = styled.span`
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: center;
`;

const MatchDate = styled(Competition)`
  font-weight: normal;
  margin-bottom: 20px;

  @media ${Devices.tabletLarge} {
    margin-bottom: 40px;
  }
`;

const Live = styled.span<{ isFinished: boolean }>`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  width: 80px;
  line-height: 20px;
  margin: 0 0 5px 0;
  color: white;
  text-transform: uppercase;
  background-color: ${({ isFinished, theme }): string => (isFinished ? theme.color.shadow : theme.color.red)};
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 65px;
  max-height: 65px;

  @media ${Devices.tabletLarge} {
    max-width: 85px;
    max-height: 85px;  }
`;

const Goals = styled.span`
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 42px;
  font-weight: bold;

  & > span {
    font-size: 26px;
  }
`;

const TeamName = styled.span`
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.28;

  @media ${Devices.tabletLarge} {
    font-size: 20px;
  }
`;

const TeamScore = styled.span`
  color: #84754e;
  font-family: ${(props): string => props.theme.fontFamily.commutersSans};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
  text-transform: lowercase;
  margin-bottom: 10px;

  @media ${Devices.tabletLarge} {
    font-size: 16px;
  }
`;

const TeamGoal = styled.span`
  color: white;
  font-family: ${(props): string => props.theme.fontFamily.arialNova};
  font-size: 13px;
  font-weight: 300;
  letter-spacing: normal;

  & > span {
    font-weight: bold;
  }
`;

const CtaButton = styled(MatchCenterButton)`
  margin-top: 10px;
  border: 1px solid ${(props): string => props.theme.color.purple};
  background: ${(props): string => props.theme.color.purple};
  color: white;

  &:hover {
    background: transparent;
    color: white;
    border: 1px solid white;
  }
`;
